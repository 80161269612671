<template>
    <div>
        <template v-if="appConfig.page_custom_index_image">
            <img class="cm-img" :src="appConfig.page_custom_index_image" alt="">
        </template>
    </div>
</template>

<script>
export default {
    name: "CustomIndex",
    props: {
        appConfig: Object
    },
    data() {
        return {}
    }
}
</script>

<style scoped lang="less">
.cm-img {
    width: 100%;
    height: auto;
}
</style>