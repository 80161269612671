<template>
    <div class="mt-guide">
        <div class="tab-summary">
            说明：引导页是用户进入小程序前，小程序数据加载时（一般5秒内）看到的页面，建议放简洁、大气的企业（个人）品牌理念、品牌文化之类的设计精美图片
        </div>
        <div class="at-input-group">
            <span class="label">页面启用：</span>
            <a-switch checked-children="开"
                      v-model="item.guide_enabled"
                      un-checked-children="关"
                      @change="triggerPreview"
            />
        </div>
        <div class="at-input-group">
            <span class="label">自动关闭时间（秒）：</span>
            <at-input @change="triggerPreview" v-model="item.guide_time"/>
        </div>
        <div class="at-input-group">
            <span class="label">内容图片：</span>
            <upload-image @change="triggerPreview" style="max-width: 150px" v-model="item.guide_image"/>

            <div class="pay-apply-tips">
                图片格式必须为：png,bmp,jpeg,jpg；不可大于2M；
                建议使用png格式图片，以保持最佳效果；
                建议图片宽度为980px
            </div>
        </div>

        <div>
            <button @click="saveChange" class="btn">保存</button>
        </div>
    </div>
</template>

<script>
import AtInput from "@/components/AtInput.vue";
import UploadImage from "@/components/UploadImage.vue";

export default {
    name: "MGuide",
    components: {UploadImage, AtInput},
    props: {
        config: Object
    },
    watch: {
        config() {
            this.init()
        }
    },
    mounted() {
        this.init()
    },
    data() {
        return {
            item: {
                guide_enabled: false,
                guide_time: 5,
                guide_image: ""
            }
        }
    },
    methods: {
        init() {
            for (let key in this.item) {
                this.item[key] = this.config[key]
            }
        },
        saveChange() {
            this.$emit('change', 'guide', this.item)
        },
        triggerPreview() {
            this.$emit('preview', 'guide', this.item)
        },
    }

}
</script>

<style scoped lang="less">

.mt-guide {
    margin-top: 16px;
}

.pay-apply-tips {
    width: 300px;
    padding-left: 16px;
}


.tab-summary {
    margin-bottom: 16px;
    width: 750px;
}
</style>